/* @font-face {
  font-family: tentang_nanti;
  src: url("./views/fonts/tentang_nanti_dafont/tentang_nanti.otf");
}

@font-face {
  font-family: oliver-regular;
  src: url("./views/fonts/other_fonts/Oliver-Regular.ttf");
} */

body {
  margin: 0;
  font-family: menlo;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
